<template>
<div>
  <nav class="header-nav">
    <div class="ph-header-first">
      <div class="logo_div">
        <a  href="/" title="tik tok voice logo" aria-label="TikTok AI Voice" class="logo-link">
          <img src="/ssr/img/tiktok_voice_logo.svg" title="TikTok text to speech" alt="TikTok tts logo" height="40px" width="50px" class="top_left_logo" />
          <img src="/ssr/img/tiktok_voice_word.svg" loading="lazy" title="TikTok AI Voice Generator" alt="TikTok AI Voice Generator logo" height="40px" width="120px" class="top_left_logo"/>
        </a>
      </div>
      <div v-if="isMobile" class="lg-hidden">
        <button type="button" class="ph-menu-button" aria-label="mobile menu" @click="toggleMenu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" style="width: 24px; height: 24px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5">
            </path>
          </svg>
        </button>
      </div>
    </div>
    <ul class="main-nav" id="js-menu" v-show="!isMobile">
      <li><a class="nav-links" title="TikTok AI Voice Generate HomePage" :href="home_href">{{ $t('comm.home') }}</a></li>
      <li><a class="nav-links" title="TikTok tts Pricing" :href="pricing_href">{{ $t('comm.pricing') }}</a></li>
      <li><a class="nav-links" title="TikTok tts Blog" :href="pricing_href">{{ $t('comm.blog') }}</a></li>
    </ul>
    <div class="nav-right" v-show="!isMobile">
      <div class="mobile-divider"></div>
      <label class="nav-right-label" aria-label="tik tok voice generater language">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" width="20" height="20">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"></path>
               </svg>
         <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tik tok AI voice" v-bind:placeholder="$t('comm.select_language')">
            <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
         </select>
      </label>
      <div v-if="!isLoggedIn">
           <button class="sign-in-btn" v-on:click="showSignIn"> {{ $t('comm.login') }} </button>
      </div>
      <div class="user_menu" v-else>
        <button class="user_button" @click="toggleUserMenu">
          <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
          <span class="user_name"> {{  currentUser.name }}</span>
          <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        <div class="dropdown-menu" id="pcUserMenu" v-show="isUserMenuOpen" >
           <a title="text to speach settings" :href="settings_href">{{ $t('setting.setting') }}</a>
           <a title="text to speach logout" href="#" @click="logout">{{ $t('comm.logout') }}</a>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-menu-overlay" v-show="isMobile && isMenuOpen" @click="toggleMenu"> </div>
  <div class="mobile-menu" :class="{ 'open': isMobile && isMenuOpen, 'instant-close': isInstantClose }">
      <ul class="main-nav">
        <li class="nav-item">
          <a class="nav-links" title="TikTok AI Voice generator HomePage" :href="home_href" @click.prevent="navigateAndCloseMenu('home')">
            {{ $t('comm.home') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok tts Pricing" :href="pricing_href" @click.prevent="navigateAndCloseMenu('pricing')">
            {{ $t('comm.pricing') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok voice clone api" :href="pricing_href" @click.prevent="navigateAndCloseMenu('voiceCloneApi')">
            {{ $t('comm.blog') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
      </ul>
      <div class="nav-right">
        <div class="mobile-divider"></div>
        <label class="nav-right-label">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" width="20" height="20">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"></path>
                 </svg>
           <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tiktok voice generator" v-bind:placeholder="$t('comm.select_language')">
              <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
           </select>
        </label>
        <div v-if="!isLoggedIn">
             <button class="sign-in-btn" v-on:click="showSignIn">{{ $t('comm.login') }}</button>
        </div>
        <div class="user_menu" v-else @click.stop>
          <button class="user_button" @click.stop="handleUserMenuInteraction" @touchstart.prevent="handleUserMenuInteraction">
            <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
            <span class="user_name"> {{  currentUser.name }}</span>
            <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                 <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <div class="dropdown-menu" id="userMenu"  v-show="isUserMenuOpen" @click.stop style="z-index: 1000;">
             <a title="tiktok tts settings" :href="settings_href">{{ $t('setting.setting') }}</a>
             <a title="tiktok text to speech logout" href="#" @click.stop="logout">{{ $t('comm.logout') }}</a>
          </div>
        </div>
      </div>
  </div>
  <div class="signCover" v-show="isSignInVisible">
    <div class="signDiv">
      <span class="closex" v-on:click="closeSignIn">&times;</span>
      <div>
        <div class="login_logo">
          <img src="/ssr/img/tiktok_voice_logo.svg" title="TikTok Voice Generator"  alt="TikTok tts logo" loading="lazy" height="60px" width="80px" class="top_left_logo"/>
          <img src="/ssr/img/tiktok_voice_word.svg" title="TikTok Voice Generator" alt="TikTok tts logo" loading="lazy" height="60px" width="160px" class="top_left_logo"/>
        </div>
	<p class="login_h1">{{ $t('comm.login_desc') }}</p>
        <!-- p class="message">Please continue by logging in</p -->
      </div>
      <div class="google-button-container">
        <div ref="googleSignInButton" class="custom-google-button"> </div>
      </div>
      <!-- button class="close-btn" v-on:click="closeSignIn"> close </button -->
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadLanguageAsync } from '../lang';
import debounce from 'lodash/debounce';
import { setCookie, delCookie, getCookie } from '../utils/cookies';

const host = "https://tiktokvoice.net"

export default {
  name: 'VoiceHeader',
  data () {
    return {
      isSignInVisible: false,
      isMobile: true,
      isMenuOpen: false,
      value: 'en', // 默认语言
      home_href: '/',
      pricing_href: '/en/pricing',
      settings_href: '/en/settings',
      isUserMenuOpen: false,
      debouncedCheckMobile: null,
      isInstantClose: false,
      debouncedToggleUserMenu: null
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser']),
    languageOptions() {
      return [
        { value: 'en', label: 'English' },
        { value: 'zh', label: '中文' },
        { value: 'zh-tw', label: '繁體中文' },
        { value: 'ja', label: '日本語' },
        { value: 'ko', label: '한국어' },
        { value: 'ru', label: 'Русский' },
        { value: 'de', label: 'Deutsch' },
        { value: 'vi', label: 'Tiếng Việt' },
        { value: 'th', label: 'ภาษาไทย' },
        { value: 'hi', label: 'हिन्दी' },
        { value: 'fa', label: 'فارسی' },
        { value: 'fr', label: 'Français' },
        { value: 'ro', label: 'Română' },
        { value: 'cs', label: 'Čeština' },
        { value: 'es', label: 'Español' },
        { value: 'pt', label: 'Português' },
        { value: 'bn', label: 'বাংলা'},
        { value: 'it', label: 'Italiano' },
        { value: 'ar', label: 'العربية'},
        { value: 'ur', label: 'اردو' },
        { value: 'ms', label: 'Bahasa Melayu' },
        { value: 'tr', label: 'Türkçe' },
        { value: 'pl', label: 'Polski' },
        { value: 'nl', label: 'Dutch' },
        { value: 'uk', label: 'Українська' },
      ]
    }
  },
  methods: {
    async changeLang (evt) {
      var lang = evt.target.value
      setCookie('locale', lang)
      this.value = lang
      // this.$i18n.locale = lang
      // this.$store.commit('setLang', lang)
      // await loadLanguageAsync(this.$i18n, lang)
      // 根据新的语言更新pricing_href和home_href
      // this.updateLinks(lang)
      // 更新 URL 但不进行导航
      const currentPath = this.$route.path
      const pathParts = currentPath.split('/').filter(Boolean)
      const availableLocales = this.languageOptions.map(option => option.value)
      // console.log("pathParts:", pathParts)
      // console.log("availableLocales:", availableLocales)

      var newPath
      if (pathParts.length <= 1) {
        if (availableLocales.includes(pathParts[0])) {
          newPath = lang === 'en' ? '/' : `/${lang}`
        } else if (pathParts[0]) {
          newPath = currentPath
        } else {
          // 如果没有值，使用新语言
          newPath = lang === 'en' ? '/' : `/${lang}`
        }
      } else {
        if (availableLocales.includes(pathParts[0])) {
          // 如果第一部分是语言，直接替换
          newPath = currentPath.replace(pathParts[0], lang)
        } else {
          // 如果第一部分不是语言，保留原值并在前面添加新语言（除非是英语）
          newPath =  currentPath
        }
      }
      window.location.href = newPath
      // history.pushState(null, '', newPath)
    },
    navigateTo(destination) {
      switch(destination) {
        case 'home':
          this.$router.push(this.home_href);
          break;
        case 'pricing':
          this.$router.push(this.pricing_href);
          break;
        case 'voiceCloneApi':
          this.$router.push(this.pricing_href);
          break;
      }
      // 如果使用的是移动菜单，在导航后关闭菜单
      if (this.isMobile) {
        this.isMenuOpen = false;
      }
    },
    handleUserMenuInteraction(event) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleUserMenu();
    },
    updateLinks(lang) {
      this.pricing_href = `/${lang}/pricing`
      this.settings_href = `/${lang}/settings`
      this.home_href = lang === 'en' ? '/' : `/${lang}`
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    checkMobile() {
      requestAnimationFrame(() => {
        this.isMobile = window.innerWidth <= 900; // 和样式保存一致
        this.isMenuOpen = false;
      });
    },
    showSignIn: function () {
      this.isSignInVisible = true
    },
    closeSignIn: function () {
      this.isSignInVisible = false
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    closeUserMenu() {
      this.isUserMenuOpen = false;
    },
    // toggleUserMenu 的实际现
    actualToggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    async initGoogleSignIn() {
      if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
        try {
          const currentOrigin = window.location.origin;

          await google.accounts.id.initialize({
            client_id: '304803644975-lmsl5t2frh2vniu0f9j9ua9pbso86fg2.apps.googleusercontent.com',
            callback: this.handleCredentialResponse,
            ux_mode: 'popup',
            origin: host
          });
          google.accounts.id.renderButton(
            this.$refs.googleSignInButton,
            { theme: 'filled_blue', size: 'large', text: 'signin_with',  locale: 'en' }
          );
        } catch (error) {
          console.error("Error initializing Google Sign-In:", error)
        }
      } else {
        console.error("Google Sign-In SDK not loaded!")
      }
    },
    handleCredentialResponse(response) {
      // Handle the sign-in response here
      // console.log("Received response:", response)
      if (response.credential) {
        // console.log("Successfully logged in with Google")
	this.sendTokenToBackend(response.credential)
      }
      this.closeSignIn()
    },
    async sendTokenToBackend(token) {

      var uri = host + '/lapi/auth/google'
      try {
        const response = await fetch(uri, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify( {token} )
        });

        if (response.ok) {
          const data = await response.json()
          // 处理成功的后端认证
          // console.log("Backend authentication successful:", data);
	  if (data.ret == 0) {
            // 处理成功的后端认证
            // console.log("Backend authentication successful:", data);
            // 提取相关的用户数据
            const userData = {
              'email': data.email,
              'name': data.full_name,
              'picture': data.picture,
            };
            // 将用户数据存储在 Vuex store 中
            this.$store.dispatch('loginUser', userData)
	    // 存储用户数据到cookie
	    setCookie('userData', JSON.stringify(userData))
	  } else {
	    console.error("reqid:" + data.reqid + ", ret:" + data.ret + ", msg:" + data.msg)
            this.$store.dispatch('logoutUser');
	    delCookie('userData')
            // document.cookie = "userData=; path=/; domain=tiktokvoice.net; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
	  }
        } else {
          console.erro('Backend authentication failed!')
          // 处理认证失败
          this.$store.dispatch('logoutUser');
	  delCookie('userData')
          // document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
          this.$emit('login-error', 'Backend authentication failed');
        }
      } catch (error) {
        console.error('Error sending token to backend:', error)
        this.$store.dispatch('logoutUser');
	delCookie('userData')
        // document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        this.$emit('login-error', 'Network error during authentication');
      }
    },
    logout() {
      this.$store.dispatch('logoutUser');
      // 清除用户数据 cookie
      delCookie('userData')
      // document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      // 清除用户信息和状态
      this.isSignInVisible = false; // 隐藏登录框
      // this.$emit('logout'); // 触发注销事件
      location.reload();
    },
    initNonCritical() {
      this.initGoogleSignIn();
    },
    navigateAndCloseMenu(destination) {
      this.isInstantClose = true;
      this.isMenuOpen = false;
      this.$nextTick(() => {
        this.navigateTo(destination);
        // 重置 isInstantClose，为下次打开做准备
        setTimeout(() => {
          this.isInstantClose = false;
        }, 50);
      });
    },
  },
  mounted () {
    this.checkMobile()
    this.debouncedCheckMobile = debounce(this.checkMobile, 50,);
    this.debouncedToggleUserMenu = debounce(this.actualToggleUserMenu, 50); // 对 toggleUserMenu 进行防抖处理
    window.addEventListener('resize', this.debouncedCheckMobile, { passive: true });
    // window.addEventListener('click', this.closeUserMenu);

    this.value = this.$store.state.lang
    this.updateLinks(this.$store.state.lang)
    // 使用 requestIdleCallback 进行非关键初始化
    // const storedUserData = getCookie('userData');
    // if (storedUserData) {
    //   this.$store.dispatch('loginUser', JSON.parse(storedUserData));
    // }
    setTimeout(() => {
	    this.initNonCritical();
    }, 0);
  },
  beforeUnmount() {
    if (this.debouncedCheckMobile) {
      window.removeEventListener('resize', this.debouncedCheckMobile)
    }
    // window.removeEventListener('click', this.closeUserMenu);
  }
}

</script>

<style scoped>
</style>

