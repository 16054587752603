<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    <div class="main" style="min-height: 600px">
        <div class="main_title" style="min-height: 100px;">
    <div>
            <H1 class="main_title_p01"><span>{{ $t('txt2voice.main_title_p01') }}</span></H1>
    </div>
    <div>
            <H2 class="main_title_p02"><span>{{ $t('txt2voice.main_title_p02') }}</span></H2>
    </div>
        </div>
        <div class="main_div">
            <div class="main_div_word">
                <div class="main_div_word_teatarea">
       <textarea
          v-model="voicetext"
          id="voicetextarea"
          type="text"
          v-bind:placeholder="$t('txt2voice.main_textarea_holder')"
          @input="handleInputOptimized"
          @focus="handleFocusOptimized"
          :maxlength="text_maxlen"
          @blur="handleBlurOptimized"
          @compositionstart="handleCompositionOptimized"
          @compositionend="handleCompositionOptimized"
          class="textarea-style"
          :class="{ 'composing': isComposing }"
       >
       </textarea>
                </div>
                <div class="main_div_word_bottom">
                    <div class="main_div_word_bottom_left">
                      <select v-model.lazy="selectLang" @change="debouncedChangeLang" class="main_div_word_bottom_left_select" aria-label="tiktok text to speech select lang">
                        <option v-for="item in filteredLangs" v-bind:key="item.value" v-bind:value="item.value">
                          {{ item.label }}
                        </option>
                      </select>
                    </div>
                    <div class="main_div_word_bottom_left">
                      <select v-model.lazy="selectModel"
                        @change="debouncedChangeModel"
                        @scroll="handleScroll"
                        class="main_div_word_bottom_left_select2"
                        aria-label="tiktok tts select model"
                        :size="1"
                        @mousedown="handleSelectMouseDown">
                        <option v-for="option in filteredModels"
                          v-bind:key="option.value"
                          v-bind:value="option.value">
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                    <div class="main_div_word_bottom_right">
                        <span class="word-counter">{{ wordcnt }}/300</span>
                        <button
                          ref='gen_button'
                          class="main_div_bottom_right_button"
                          :class="{ 'loading': isLoading }"
                          v-on:click="handleGenVoiceClick"
                          v-bind:disabled="disabled || isLoading">
                          <span v-if="!isLoading">{{ $t('txt2voice.main_genvoice') }}</span>
                          <span v-else class="loading-spinner"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="main_div_audio">
                <div id="wrapper" class="main_div_audio_box">
                    <div class="audio_container">
                      <audio ref='audio_main' title="tiktok audio voice to speech" controls="controls" preload="metadata" id="audio_tagert" class="main_div_audio_css">
                        <source :src="selectModelWavplay">
                      </audio>
                    </div>
        <div class="main_div_audio_buttons">
                      <!-- a ref='audio_down' title="tiktok voice generator download" :href="selectModelWavplay" id="audio_down_url" class="main_div_audio_button" aria-label="TikTok AI Voice audio download" target="_blank">
                          <img src="/ssr/img/upload.png" title="TikTok Voice Generator download" alt="TikTok text to speech upload" width="24"  height="24"/>
                      </a -->
                      <button
                      @click="handleDownload"
                      class="main_div_audio_button"
                      :disabled="isDownloading"
                      :class="{ 'downloading': isDownloading }"
                        aria-label="TikTok audio voice to speech download">
                        <img
                          src="/ssr/img/upload.png"
                          :title="isDownloading ? 'Downloading...' : 'TikTok Voice Generator download'"
                          alt="..."
                          width="24"
                          height="24"
                        />
                      </button>
        </div>
                </div>
            </div>
        </div>

        <div class="faq_section">
              <H2 class="main_div_quest_about">{{ $t('txt2voice.about_title') }}</H2>

              <!-- FAQ手风琴部分 -->
              <div class="faq-accordion">
                <div v-for="i in 12" :key="i" class="faq-item">
                  <div class="faq-header" @click="toggleFaq(i)">
                    <H3 class="main_div_quest">{{ $t(`faq.quest${i}`) }}</H3>
                    <span class="faq-icon" :class="{ 'active': activeFaq === i }">›</span>
                  </div>
                  <div class="faq-content" :class="{ 'active': activeFaq === i }">
                    <p v-for="j in getAnswerCount(i)"
                       :key="j"
                       class="main_div_answer">
                      {{ $t(`faq.answer${i}_${j}`) }}
                    </p>
                  </div>
                </div>
              </div>
        </div>

    </div>
    <div id="popupContainer">
      <PopupModal
          v-for="(data, id) in popupData"
          :key="id"
          :data="data"
          :pricing-href="pricing_href"
          v-if="popupStates[id]"
          @close="closePopup(id)"
        />
    </div>
    <div class="footer" id="bloc-10">
      <div>
        <voice-footer></voice-footer>
      </div>
    </div>
  </div>
  </template>

  <script>
  import { mapGetters } from 'vuex'
  import { defineAsyncComponent } from 'vue'
  import { reactive } from 'vue'
  import axios from 'axios'
  import debounce from 'lodash/debounce';
  import { setCookie, delCookie, getCookie } from '../utils/cookies';
  import VoiceHeader from '@/components/VoiceHeader.vue'
  import { onINP } from 'web-vitals/attribution';

  const api_host = 'https://api.tiktokvoice.net'
  // 异步加载组件
  const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))
  const PopupModal = defineAsyncComponent(() => import('@/components/PopupModal.vue'))
  // 定义弹窗类型常量
  const POPUP_TYPES = {
    NOLOGIN: 'popup_nologin',
    NOSUB: 'popup_nosub',
    SUBLIMIT: 'popup_sublimit'
  }

  export default {
    name: 'home',
    components: {
      VoiceHeader,
      VoiceFooter,
      PopupModal
    },
    data() {
      return {
        langsModels: null,
        allLangs: null,
        selectLang: null,
        selectLangModels: null,
        selectModel: null,
        selectModelWavplay: '',
        voicetext: '',
        disabled: false,
        wordcnt: 0,
        text_maxlen: 300,
        email: '',
        user_subscript: 0,  // 有4个取值, 0 未订阅, 1 已订阅, 2 虽,字符已用完
        pricing_href: '/en/pricing',
        isComposing: false,
        inputTimer: null,
        pageSize: 25,
        currentPage: 0, // 当前页码
        isLoadingMore: false, // 是否正���加载更多
        isLoading: false,
        isDownloading: false,
        popupData: {
            // 未登录用户
            [POPUP_TYPES.NOLOGIN]: {
          title: this.$i18n.t('txt2voice.popup_nologin_title'),
          description: this.$i18n.t('txt2voice.popup_nologin_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_nologin_btntext'),
            },
            // 登录用户,未订阅
            [POPUP_TYPES.NOSUB]: {
          title: this.$i18n.t('txt2voice.popup_nosub_title'),
          description: this.$i18n.t('txt2voice.popup_nosub_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_nosub_btntext'),
            },
            // 登录用户, 已达到订阅套餐上限
            [POPUP_TYPES.SUBLIMIT]: {
          title: this.$i18n.t('txt2voice.popup_sublimit_title'),
          description: this.$i18n.t('txt2voice.popup_sublimit_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_sublimit_btntext'),
            }
        },
        popupStates: reactive({}),
        activeFaq: null, // 当前展开的FAQ项
        faqAnswerCounts: {
          1: 4,
          2: 4,
          3: 5,
          4: 2,
          5: 3,
          6: 3,
          7: 3,
          8: 3,
          9: 3,
          10: 8,
          11: 1,  // 修改为1，因为quest11只有一个answer
          12: 1   // 修改为1，因为quest12只有一个answer
        }
      }
    },
    head() {
      return {
        'title': this.$i18n.t('txt2voice.title'),
        'keywords': this.$i18n.t('txt2voice.keywords'),
        'description': this.$i18n.t('txt2voice.description')
      }
    },
    metaInfo() {
      return {
        link: [
        { rel: 'alternate', hreflang: 'x-default', href: 'https://tiktokvoice.net/' },
          { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/' },
          { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja' },
          { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh' },
          { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw' },
          { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko' },
          { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi' },
          { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th' },
          { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi' },
          { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa' },
          { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru' },
          { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de' },
          { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr' },
          { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro' },
          { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs' },
          { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es' },
          { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt' },
          { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn' },
          { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it' },
          { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar' },
          { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur' },
          { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms' },
          { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr' },
          { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl' },
          { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl' },
          { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk' },
        ]
      }
    },
    asyncData: function ({ store, route }) {
      // console.log('home state count:' + store.state.count)
      return store.dispatch("fetchData")
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser', 'authToken']),
      filteredLangs() {
        return this.allLangs?.slice(0, 25); // 限制为前20项
      },
      filteredModels() {
        // 使用计算属性缓存过滤后的模型列表
        if (!this.selectLangModels) return [];
        return this.selectLangModels.slice(0, 100); // 限制最大数量为100个选项
      }
    },
    watch: {
      isLoggedIn(newValue) {
        if (newValue && this.currentUser) {
          this.getUserInfo(this.currentUser.email);
        }
      }
    },
    methods: {
      async playAudio() {
        try {
          // 检查音频元素是否存在
          if (!this.$refs.audio_main) return;
          
          const audio = this.$refs.audio_main;
          
          // 如果正在播放，先停止
          if (!audio.paused) {
            await audio.pause();
          }
          
          // 重置到开始位置
          audio.currentTime = 0;
          
          // 使用 try-catch 包装 play() 调用
          try {
            await audio.play();
          } catch (error) {
            if (error.name === 'AbortError') {
              // 如果是中断错误，等待一小段时间后重试
              await new Promise(resolve => setTimeout(resolve, 100));
              await audio.play();
            } else {
              this.reportError(error, 'playAudio');
            }
          }
        } catch (error) {
          // console.warn('Audio playback failed:', error);
          // 可以选择向用户显示错误提示
          this.reportError(error, 'playAudio');
        }
      },
      changeLang: function (evt) {
        var value = evt.target.value
        if (evt != null && (value in this.langsModels)) {
          this.selectlang = value
          this.selectLangModels = this.langsModels[value].slice()
          this.selectModel = this.selectLangModels[0].value
          this.$refs.audio_main.src = this.selectLangModels[0].wavplay
          this.selectModelWavplay = this.selectLangModels[0].wavplay
          // this.$refs.audio_down.src = this.selectLangModels[0].wavplay
        }
      },
      debouncedChangeLang: debounce(function(evt) {
        this.changeLang(evt);
      }, 50),
      wordCnt() {
        // 优化字数统计逻辑
        const text = this.voicetext
        requestAnimationFrame(() => {
          if (text.length > this.text_maxlen) {
            this.voicetext = text.slice(0, this.text_maxlen)
          }
          // 使用位运算加速计算
          this.wordcnt = text.length
        })
      },    // 使用 RAF 优化更新
      // 优化的合成输入处理
      handleCompositionOptimized(event) {
        // 使用 requestAnimationFrame 优化状态更新
        requestAnimationFrame(() => {
          this.isComposing = event.type === 'compositionstart'

          if (event.type === 'compositionend') {
            // 使用 queueMicrotask 确保在下一个微任务中处理输入
            queueMicrotask(() => {
              this.handleInputOptimized(event)
            })
          }
        })
      },
      // 优化的输入处理
      handleInputOptimized: debounce(function(event) {
        if (this.isComposing) return

        // 使用 Promise.resolve().then() 将更新推入微任务队列
        Promise.resolve().then(() => {
          requestAnimationFrame(() => {
            const value = event.target.value
            // 批量更新状态
            this.$nextTick(() => {
              this.voicetext = value
              this.wordCnt()
            })
          })
        })
      }, 16), // 16ms 约等于一帧的时间
      // 优化的焦点处理
      handleFocusOptimized(event) {
        const target = event.target
          if (target.value === target.getAttribute('placeholder')) {
          target.value = ''
          target.style.color = '#000'
        }
      },
      // 优化的失焦处理
      handleBlurOptimized(event) {
        const target = event.target
        if (!target.value) {
          target.value = target.getAttribute('placeholder')
          target.style.color = '#c9caca'
        }
      },
      // 优化 select 点击事件
      handleSelectMouseDown(event) {
        // 如果已经到底部且还有更多数据，加载下一页
        if (!this.isLoadingMore &&
            event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 50) {
          this.loadMoreOptions();
        }
      },
      // 分页加载更多选项
      async loadMoreOptions() {
        if (this.isLoadingMore) return;

        this.isLoadingMore = true;

        try {
          await new Promise(resolve => requestAnimationFrame(resolve));
          this.currentPage++;
        } finally {
          this.isLoadingMore = false;
        }
      },
      // 优滚动处理
      handleScroll: debounce(function(event) {
        const select = event.target;
        // 检查是否接近底部
        if (select.scrollTop + select.clientHeight >= select.scrollHeight - 50) {
          this.loadMoreOptions();
        }
      }, 50),
      // 优化 model 变更处理
      debouncedChangeModel: debounce(function(evt) {
        requestAnimationFrame(() => {
          this.changeModel(evt);
        });
      }, 50),
      changeModel(evt) {
        const value = evt.target.value;
        if (!value || !this.selectLang || !(this.selectLang in this.langsModels)) return;

        const model = this.langsModels[this.selectLang].find(m => m.value === value);
        if (!model) return;

        // 使用 requestAnimationFrame 优化 DOM 更新
        requestAnimationFrame(() => {
          if (this.$refs.audio_main) this.$refs.audio_main.src = model.wavplay;
          this.selectModelWavplay = model.wavplay;
          // if (this.$refs.audio_down) this.$refs.audio_down.href = model.wavplay;
        });
      },
      // 音频更新逻辑
      async updateAudioElements(audioUrl) {
        try {
          // 检查音频元素是否存在
          if (!this.$refs.audio_main) {
            this.reportError(new Error('Audio element not found'), 'updateAudioElements');
            return
          }

          const audio = this.$refs.audio_main;

          // 使用 Promise 包装音频加载过程
          await new Promise((resolve, reject) => {
            // 更新音频源
            audio.src = audioUrl;
            this.selectModelWavplay = audioUrl;

            // 监听加载完成事件
            const handleLoaded = () => {
              audio.removeEventListener('loadeddata', handleLoaded);
              audio.removeEventListener('error', handleError);
              resolve();
            };

            // 监听加载错误事件
            const handleError = (error) => {
              audio.removeEventListener('loadeddata', handleLoaded);
              audio.removeEventListener('error', handleError);
              reject(error);
            };

            audio.addEventListener('loadeddata', handleLoaded, { once: true });
            audio.addEventListener('error', handleError, { once: true });
          });

          // 音频加载完成后尝试播放
          this.playAudio();

        } catch (error) {
          // console.warn('Failed to update or play audio:', error);
          this.reportError(error, 'updateAudioElements');
        }
      },
      // 计数器更新逻辑
      async updateCharacterCounter(textlen) {
        if (!this.email) return

        try {
          const uri = `${api_host}/lapi/counter`
          const formData = new FormData()
          formData.append('email', this.email)
          formData.append('textlen', textlen)

          await axios.post(uri, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        } catch (error) {
          // console.error('Error character counter:', error)
          this.reportError(error, 'updateCharacterCounter')
        }
      },
      genVoice: async function () {
        // 1. 立即行输入验证
        if (!this.voicetext?.trim()) {
          alert(this.$i18n.t('txt2voice.main_input_empty'))
          return false
        }
        // 2. 提前准备数据
        const model = this.selectModel.split('+')
        const formdata = {
          modelcat: model[0],
          modelname: model[1],
          text: this.voicetext.slice(0, this.text_maxlen),
          subscript: this.user_subscript,
          email: this.email,
          userid: 0,
          t: 1
        }
        try {
          // 4. 使用常量避免重复计算
          const uri = `${api_host}/api/genaudio`
          const { data } = await axios.post(uri, formdata, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
          })
          // 5. 使用 switch 替代多个 if-else
          switch (data.ret) {
            case 0:
              // 6. 使用 Promise.all 并行处理
              await Promise.all([
                this.updateAudioElements(`${api_host}${data.uri}`),
                this.updateCharacterCounter(data.textlen)
              ])
              break
            case 2:
              const popid = this.isLoggedIn
                ? (this.user_subscript === 2 ? POPUP_TYPES.SUBLIMIT : POPUP_TYPES.NOSUB)
                : POPUP_TYPES.NOLOGIN
              this.openPopup(popid)
              break
            default:
              alert(data.msg)
          }
        } catch (error) {
          // console.error('Error generating voice:', error)
          this.reportError(error, 'genVoice')
          alert('An error occurred while generating the voice, please try again later!')
        }
      },
      async getUserInfo (email) {
        if (!email) {
          // console.log("email is empty, email:" + email)
          return false
        }
        try {
          // const host = this.$i18n.t('host')
          const uri = `${api_host}/lapi/user/profile`
          const { data } = await axios.get(uri, {
            params: { email },
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
          })

          if (data.ret === 0 && data.user_info) {
            this.email = data.user_info.email
            this.user_subscript = data.user_info.user_subscript
          } else {
            console.log("ret:" + data.ret + ", msg:" + data.msg)
          }
        } catch (error) {
          this.reportError(error, 'getUserInfo')
          // console.error('Error fetching user information:', error)
          // console.log('An error occurred while fetching user information, please try again later!')
        }
      },
      async handleGenVoiceClick() {
        // 防止重复点击
        if (this.isLoading) return;
        // 使用 requestAnimationFrame 优化状态更新
        requestAnimationFrame(() => {
          this.isLoading = true;
        });

        // 使用 setTimeout 将验证逻辑移到下一个事件循环
        setTimeout(async () => {
          try {
            await this.genVoice();
          } finally {
            requestAnimationFrame(() => {
              this.isLoading = false;
            });
          }
        }, 0);
      },
      async handleDownload() {
        // console.log('[Download] Starting download process');

        if (!this.selectModelWavplay) {
          alert('There is no audio! Please generate audio first!');
          return;
        }

        if (this.isDownloading) {
          // console.log('[Download] Already downloading, skipping');
          return;
        }

        // console.log('[Download] Setting downloading state');
        this.isDownloading = true;

        try {
          // 如果音频正在加载，等待加载完成
          if (this.$refs.audio_main) {
            if (this.$refs.audio_main.readyState === 0) {
              await new Promise((resolve, reject) => {
                const audio = this.$refs.audio_main;
                audio.addEventListener('loadeddata', () => {
                  // console.log('[Download] Audio loaded successfully');
                  resolve();
                }, { once: true });
                audio.addEventListener('error', (error) => {
                  // console.log('[Download] Audio loading failed:', error);
                  reject(error);
                }, { once: true });
              });
            }
          }
          const filename = `tiktok-voice-${new Date().getTime()}.mp3`;

          const response = await fetch(this.selectModelWavplay);

          if (!response.ok) {
            // console.error('[Download] Fetch failed with status:', response.status, response.statusText);
            this.reportError(new Error('Download failed'), 'handleDownload');
            alert('Audio donwload failed, Please try later!');
            return
          }

          const blob = await response.blob();

          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } catch (error) {
          // console.error('Download error:', error);
          this.reportError(error, 'handleDownload')
          alert('Failed to download audio file!');
        } finally {
          this.isDownloading = false;
        }
      },
      initializePopups() {
        Object.keys(this.popupData).forEach(id => {
          this.popupStates[id] = false
        })
      },
      openPopup(id) {
        if (this.popupData[id]) {
          this.popupStates[id] = true
        }
      },
      closePopup(id) {
        if (this.popupStates[id] !== undefined) {
          this.popupStates[id] = false
        }
        this.$forceUpdate();
      },
      reportWebVital(metric) {
        try {
           // const host = this.$i18n.t('host')
          const uri = `${api_host}/lapi/webvitals`
          const formData = new FormData()
          formData.append('metric_id', metric.id)
          formData.append('metric_name', metric.name)
          formData.append('metric_value', metric.value)
          formData.append('metric_delta', metric.delta)
          formData.append('metric_rating', metric.rating)
          formData.append('metric_navigation', metric.navigationType)

          // formData.append('attribution',  JSON.stringify(metric.entries))
          formData.append('attribution',  JSON.stringify(metric.attribution))
          const { data } = axios.post(uri, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        } catch (error) {
          // console.error('Error character counter:', error)
          this.reportError(error, 'handleDownload')
        }
        // console.log("reportWebVital end!!")
      },
      // 优化合成输入(IME)处理
      handleCompositionOptimized: debounce(function(event) {
        this.isComposing = event.type === 'compositionstart'
        if (!this.isComposing) {
          this.handleInputOptimized(event)
        }
      }, 50),
      // 优化 v-model 更新
      handleFocusOptimized: debounce(function(event) {
        if (event.target.value === event.target.getAttribute('placeholder')) {
          event.target.value = '';
          event.target.style.color = '#000';
        }
      }, 50),
      // 优化 v-model 更新
      handleBlurOptimized: debounce(function(event) {
        if (!event.target.value) {
          event.target.value = event.target.getAttribute('placeholder');
          event.target.style.color = '#c9caca';
        }
      }, 50),
      toggleFaq(index) {
        if (this.activeFaq === index) {
          this.activeFaq = null;
        } else {
          this.activeFaq = index;
        }
      },
      getAnswerCount(index) {
        return this.faqAnswerCounts[index] || 0;
      },
      async reportError(error, context) {
        try {
          const uri = `${api_host}/lapi/weberrors`
          const formData = new FormData()
          formData.append('error', error.message || error)
          formData.append('stack', error.stack || '')
          formData.append('context', context)
          formData.append('url', window.location.href)
          formData.append('timestamp', new Date().toISOString())

          await axios.post(uri, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        } catch (reportError) {
          // 如果上报失败，静默处理
          // console.warn('Failed to report error:', reportError)
          ; // 什么也不做
        }
      }
    },
    async created() {
      // 使用 Object.assign 一次性设置多个属性，减少响应式更新
      Object.assign(this, {
        langsModels: this.$store.state.langsModels,
        allLangs: this.$store.state.allLangs,
        selectLang: this.$store.state.selectLang,
        selectLangModels: this.$store.state.selectLangModels,
        selectModel: this.$store.state.selectModel,
        selectModelWavplay: this.$store.state.selectModelWavplay
      });
    },
    mounted () {
      this.pricing_href = '/' + this.$store.state.lang + '/pricing'

      this.$nextTick(() => {
        const textarea = document.getElementById('voicetextarea')
        if (textarea) {
          textarea.focus()
        }
      })

      if (this.isLoggedIn && this.currentUser) {
        this.getUserInfo(this.currentUser.email);
      }

      // 初始化弹窗状态
      this.initializePopups()

      // Add Web Vitals reporting
      // console.log("report webVital 1")
      // 暂时不上报了
      // try {
      //   onINP((metric) => this.reportWebVital(metric));
      // } catch (error) {
      //   console.error('Failed to initialize INP monitoring:', error);
      // }

        // 添加全局错误监听
      window.addEventListener('error', (event) => {
         this.reportError(event.error, 'window.error')
      })

      // 添加Promise错误监听
      window.addEventListener('unhandledrejection', (event) => {
        this.reportError(event.reason, 'unhandledrejection')
      })
    }
  }
  </script>

<style scoped>
</style>