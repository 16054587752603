<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy" class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <a href="/en/about" class="footer-link-last">{{ $t('comm.about_us') }}</a>
        <span class="footer-link-last">{{ $t('comm.footer_help') }} tiktokaivoicetool@gmail.com</span>
      </div>
    </div>

    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>

    <div class="friend-links">
      <a v-for="(link, index) in flattenedLinks"
         :key="index"
         :href="link.href"
         :title="link.text"
         target="_blank"
         rel="noopener noreferrer"
         class="friend-link">
        {{ link.text }}
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data() {
    return {
      footerColumns: [
        [
          { href: "https://aivoicelab.net", text: "AIVoiceLabe" },
          { href: "https://bai.tools", text: "BAI.tools" },
          { href: "https://ai-findr.com/", text: "AI Findr Tools" },
          { href: "https://freeaitool.ai/", text: "Free AI Tool" },
        ],
        [
          { href: "https://seektop.ai", text: "SeekTop.ai" },
          { href: "https://allinai.tools", text: "All in AI" },
          { href: "https://aipure.ai/", text: "AIPURE AI" },
          { href: "https://aitoolcenter.com/", text: "AI Tool Center" },
        ],
        [
          { href: "https://ainavigationsite.com/", text: "AI Navigation Site Tools " },
          { href: "https://aistage.net", text: "AIStage" },
          { href: "https://castle.xyz/@benjamin1900", text: "Benjamin's Castle" },
          { href: "https://bestaitool.substack.com/p/3-best-c3po-voice-text-to-speech", text: "Best AI Tool Blog" },
          { href: "https://chrome-alley-19e.notion.site/3-Best-C3PO-Voice-Text-to-Speech-TTS-530c370932674611bda1b8c08c76d94d", text: "C3PO TTS Guide" }
        ]
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    },
    flattenedLinks() {
      return this.footerColumns.flat()
    }
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.friend-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.friend-link {
  margin: 0 0.5rem;
  color: #fff;
  text-decoration: none;
}
</style>
